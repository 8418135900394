<template>
    <van-panel
        class="video-item"
        :title="video.title"
        :desc="formatDesc(video)"
    >
        <div class="item-cnt" @click="handleImageClick">
            <my-image :src="video.thumb_videolist" :alt="video.title"></my-image>
        </div>
    </van-panel>
</template>

<script>
import MyImage from '@/components/MyImage'

export default {
    name: 'VideoItem',
    props: {
        video: {
            required: true,
            type: Object
        }
    },
    data() {
        return {}
    },
    methods: {
        formatDesc(video) {
            if (video.author) {
                return video.author + ' · ' + video.created_at
            }
            return video.created_at
        },
        handleImageClick() {
            this.$router.push(`/video/${this.video.id}`)
        }
    },
    components: { MyImage },
    created() {
    }
};
</script>

<style lang="less">
    .video-item {
        .van-panel__header {
            padding: 10px;
            .van-cell__title {
                span {
                    height: 19px;
                    display: inline-block;
                    overflow: hidden;
                }
            }
        }

        .item-cnt {
            padding: 10px;

            .meta {
                position: absolute;
                left: 0px;
                right: 0px;
                padding: 0px 32px;
                bottom: 25px;
                color: #fff;
                p {
                    margin: 0px;
                }
                span {
                    margin-left: 2px;
                }

                .meta-view {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .meta-time {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
            img {
                width: 100%;
            }
        }
    }
</style>
