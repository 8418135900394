<template>
    <div>
        <van-search v-model="keyword" @search="handleSearch" @clear="handleClear" placeholder="请输入关键词搜索"/>
        <banner v-if="banners.length !== 0" :dataset="banners" />
        <middle-loading :loading="categoryLoading" v-if="categoryLoading" />

        <van-tabs v-else @change="handleTabChange" sticky color="#1989fa">
            <van-tab :key="category.id" :name="category.id" v-for="category in categories" :title="category.name">
                <div class="row">
                    <van-list
                        :offset="50"
                        v-model="listLoading"
                        :finished="listFinished"
                        finished-text="没有更多了"
                        @load="onLoad"
                        :error.sync="error"
                        error-text="加载,点击重新加载"
                        :ref="'mList' + category.id"
                    >
                        <div class="van-clearfix">
                            <div :key="video.id" v-for="video in videos.data" class="col">
                                <video-item :video="video"/>
                            </div>
                        </div>
                    </van-list>
                </div>
            </van-tab>
        </van-tabs>
        <div class="infor-display">
            <p @click="licenceView" v-if="church.meta.religious_license">互联网宗教信息服务许可证：{{ church.meta.religious_license }}</p>
            <p>
              <a href="https://beian.miit.gov.cn/" target="_blank" v-if="church.meta.filling_no">{{ church.meta.filling_no }}</a>
              <span style="margin-left: 12px" v-if="church.meta.public_security_filling_no">公安备案号：{{ church.meta.public_security_filling_no }}</span>
            </p>
        </div>
        <tabbar />
    </div>
</template>

<script>
import { ImagePreview } from 'vant';
import { getVideos, getChurchByDomain } from '@/api/media'
import { getCategories } from '@/api/category'
import { getBanners } from '@/api/banner'
import VideoItem from './VideoItem'
import Banner from '@/components/Banner'
import Tabbar from '@/components/Tabbar'
import MiddleLoading from '@/components/MiddleLoading'

export default {
    name: 'VideoIndex',
    components: { VideoItem, Banner, Tabbar, MiddleLoading },
    data() {
        return {
            church: {
                meta: {
                    religious_license: '',
                    filling_no: '',
                    public_security_filling_no: '',
                    religious_license_url: ''
                }
            },
            keyword: null,
            banners: [],
            categoryLoading: true,
            listLoading: false,
            listFinished: false,
            error: false,
            categories: [
                {
                    name: '全部',
                    id: 0
                }
            ],
            videos: {
                meta: {
                    current_page: 0,
                    last_page: 0
                },
                data: []
            },
            category_id: null,
        }
    },
    methods: {
        licenceView () {
            if (this.church.meta.religious_license_url) {
                ImagePreview([this.church.meta.religious_license_url])
            }
        },
        handleSearch () {
            this.handleTabChange(this.category_id)
        },
        handleClear () {
            this.keyword = null
            this.handleTabChange(this.category_id)
        },
        handleTabChange(index) {
            this.category_id = index || null
            this.refresh()
        },
        refresh () {
            this.videos.data = []
            this.videos.meta.current_page = 0
            this.listFinished = false
            this.listLoading = true
            this.onLoad();
        },
        async onLoad() {
            const churchRes = await getChurchByDomain()
            this.church = churchRes.data
            const query = {
                church_id: churchRes.data.id,
                page: this.videos.meta.current_page + 1,
                title: this.keyword
            }
            if (this.category_id) {
                query.category_id = this.category_id
            }
            getVideos(query).then(res => {
                res.data.unshift(...this.videos.data)
                this.videos = res
                this.listLoading = false
                // 数据全部加载完成
                if (res.meta.current_page === res.meta.last_page) {
                    this.listFinished = true
                }
            }).catch(() => {
                this.error = true
            })
        }
    },
    created() {
        getBanners({ position: 'video' }).then(res => {
            this.banners = res.data
        })
        getCategories({ type: 'video' }).then(res => {
            this.categories.push(...res.data)
            this.categoryLoading = false
        })
    }
};
</script>

<style lang="less" scoped>
    .van-tab__pane {
        padding: 0px 8px;
    }
    .row {
        margin: 0px -4px;
    }
    .col {
        padding: 4px 8px;
        width: 50%;
        box-sizing: border-box;
        float: left;
        margin-top: 8px;
        .van-hairline--top-bottom {
            &::after {
                border: none;
            }
        }
    }
    .infor-display {
        position: relative;
        width: 100%;

        a, p {
            color: #969799;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
        }
    }
</style>
